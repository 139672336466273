import { useSelector } from "react-redux";
import { AppHead } from "@/components/generic/app/AppHead";
import { Redirect } from "@/components/generic/navigation/Redirect";
import LayoutEmpty from "@/components/layout/LayoutEmpty";
import { withOptionalUser, WithUserProps } from "@/components/wrappers/WithUser";
import { featuresSelectors } from "@/core/store/redux/features/selectors";
import { FeaturesProviderSelection } from "@/core/store/redux/features/types";

const IndexPage: React.FC<WithUserProps> = ({ user }) => {
  const featuresConfig = useSelector(featuresSelectors.config);
  let redirect = "/onboarding/start";
  if (user) {
    if (featuresConfig.providerSelection == FeaturesProviderSelection.AUTOMATED) {
      redirect = "/chat"; // change with chat
    } else {
      redirect = "/providers";
    }
  }

  return (
    <>
      <AppHead title="Homepage" />
      <LayoutEmpty>
        <Redirect to={redirect} loading />
      </LayoutEmpty>
    </>
  );
};

export default withOptionalUser(IndexPage);
