import { ReduxState } from "@/store/types";

import { FeaturesConfig, FeaturesState } from "./types";

const self = (state: ReduxState): FeaturesState => state.features;
const config = (state: ReduxState): FeaturesConfig => state.features.config;
export const featuresSelectors = {
  self,
  config,
};
