import { useEffect, useState } from "react";
import { isServer } from "../nextjs";

export type HashParams = Record<string, string>;

export function useInitialHashParams(): HashParams {
  const hashParams = isServer() ? {} : extractHashParams(window.location.hash);
  return hashParams;
}

// Hook to parse and manage URL hash parameters
export function useHashParams(): HashParams {
  const [hashParams, setHashParams] = useState<HashParams>({});

  useEffect(() => {
    if (isServer()) return;

    const hash = window.location.hash.slice(1);
    const params = new URLSearchParams(hash);
    const paramsObj: HashParams = {};
    params.forEach((value, key) => {
      paramsObj[key] = value;
    });
    setHashParams(paramsObj);

    const handleHashChange = () => {
      const newParams = new URLSearchParams(window.location.hash.slice(1));
      const newParamsObj: HashParams = {};
      newParams.forEach((value, key) => {
        newParamsObj[key] = value;
      });
      setHashParams(newParamsObj);
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return hashParams;
}

export function removeHashFromUrl(url: string): string {
  return url.split("#")[0];
}

export function addHashToUrl(url: string, hashParams?: HashParams): string {
  // strip # from url
  const [urlWithoutHash, hash] = url.split("#");
  const params = new URLSearchParams(hash);
  for (const key in hashParams) {
    params.set(key, hashParams[key]);
  }
  const newHash = params.toString();
  return urlWithoutHash + (newHash ? "#" + newHash : "");
}

export function hashParamsToString(params: HashParams): string {
  return new URLSearchParams(params).toString();
}

export function extractHashParams(url: string): HashParams {
  const [_, hash] = url.split("#");
  const params = new URLSearchParams(hash);
  const paramsObj: HashParams = {};
  params.forEach((value, key) => {
    paramsObj[key] = value;
  });
  return paramsObj;
}
