import { NextPage } from "next";

import { useSelector } from "react-redux";
import { authSelectors } from "@/core/store/redux/auth/selectors";
import { User, UserStatusType } from "@/core/store/redux/auth/types";

import { Redirect } from "../generic/navigation/Redirect";

export type WithUserProps = {
  user: User;
};
type ExcludedProps<P> = Pick<P, Exclude<keyof P, keyof WithUserProps>>;

export function withHasUser<P extends WithUserProps>(Component: React.FC<P>): React.FC<ExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    if (!user) return <Redirect to="/auth/login/start" />;
    return <Component {...props} />;
  }
  return WithWrapper;
}

export function withOptionalUser<P extends WithUserProps>(Component: React.FC<P>): React.FC<ExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    return <Component {...props} user={user} />;
  }
  return WithWrapper;
}

export function withHasConfirmedUser<P extends WithUserProps>(Component: React.FC<P>): React.FC<ExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    if (!user) return <Redirect to="/auth/login/start" />;
    if (user.status == UserStatusType.PENDING_CONFIRMATION) {
      return <Redirect to="/auth/confirm-email/pending" />;
    }
    return <Component {...props} />;
  }
  return WithWrapper;
}

export function withHasUnConfirmedUser<P extends WithUserProps>(Component: React.FC<P>): React.FC<ExcludedProps<P>> {
  function WithWrapper(props: any): JSX.Element {
    const user = useSelector(authSelectors.user);
    if (!user) return <Redirect to="/auth/login/start" />;
    if (user.status == UserStatusType.ACTIVE) {
      return <Redirect to="/" />;
    }
    return <Component {...props} />;
  }
  return WithWrapper;
}
